import { Injectable } from '@angular/core'
import {
  HttpService,
  ICompareWithSelectionAndTime,
  IExportFileResponse
} from '@mg-platform/core/core-data-access'
import { Observable } from 'rxjs'
import { ICategoryListResponse, ICategorySummaryResponse } from './interfaces/categories.interface'
import { CATEGORIES_API_URL } from './categories-api-routes'
import { DateService } from '@mg-platform/core/core-util'

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {
  constructor(
    private http: HttpService,
    private dateService: DateService
  ) {}

  getCategoriesList(payload: Partial<ICompareWithSelectionAndTime>): Observable<ICategoryListResponse> {
    return this.http.post<ICategoryListResponse>(CATEGORIES_API_URL.getCategoriesList, {
      ...payload,
      startDate: payload?.startDate ? this.dateService.momentToDateString(payload.startDate) : undefined,
      endDate: payload?.endDate ? this.dateService.momentToDateString(payload.endDate) : undefined
    })
  }

  getCategories(payload: Partial<ICompareWithSelectionAndTime>): Observable<ICategorySummaryResponse> {
    return this.http.post<ICategorySummaryResponse>(CATEGORIES_API_URL.getCategories, {
      ...payload,
      startDate: payload?.startDate ? this.dateService.momentToDateString(payload.startDate) : undefined,
      endDate: payload?.endDate ? this.dateService.momentToDateString(payload.endDate) : undefined
    })
  }

  exportCategories(payload: Partial<ICompareWithSelectionAndTime>): Observable<IExportFileResponse> {
    return this.http.post(CATEGORIES_API_URL.exportCategories, {
      ...payload,
      startDate: payload?.startDate ? this.dateService.momentToDateString(payload.startDate) : undefined,
      endDate: payload?.endDate ? this.dateService.momentToDateString(payload.endDate) : undefined
    })
  }
}
