import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs'
import { CategoriesStateModel } from './categories.model'
import { CategoriesService } from '../../categories.service'
import { GetCategories, GetCategoriesList } from './categories.actions'
import { ICategoryListResponse } from '../../interfaces/categories.interface'

@State<CategoriesStateModel>({
  name: 'categories'
})
@Injectable()
export class CategoriesState {
  constructor(private categoriesService: CategoriesService) {}

  @Selector()
  static response(state: CategoriesStateModel) {
    return state.response
  }

  @Selector()
  static listResponse(state: CategoriesStateModel): ICategoryListResponse | undefined {
    if (state.listResponse) {
      return {
        categorySales: state.listResponse.categorySales?.map((el) => ({
          ...el,
          time: el.day.split('T')[0]
        }))
      }
    }

    return
  }

  @Action(GetCategories)
  getCategories(ctx: StateContext<CategoriesStateModel>, { payload }: GetCategories) {
    ctx.patchState({
      response: undefined
    })
    return this.categoriesService.getCategories(payload).pipe(
      tap((res) => {
        ctx.patchState({
          response: res
        })
      })
    )
  }

  @Action(GetCategoriesList)
  getCategoriesList(ctx: StateContext<CategoriesStateModel>, { payload }: GetCategoriesList) {
    ctx.patchState({
      listResponse: undefined
    })
    return this.categoriesService.getCategoriesList(payload).pipe(
      tap((res) => {
        ctx.patchState({
          listResponse: res
        })
      })
    )
  }
}
