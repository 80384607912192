import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { CombinedGuard, CurrentUserGuard, LoggedInGuard } from '@mg-platform/core/core-feature'
import { ApplicationRoutes } from './app-routes-names'
import { AutovitalsGuard } from './core/guards/autovitals.guard'
import { CheckUserShopGuard } from './core/guards/check-user-shop.guard'
import { OnlyEnterpriseUserGuard } from './core/guards/only-enterprise-user.guard'
import { OnlyUserGuard } from './core/guards/only-user.guard'
import { StatesResolver } from './core/resolvers/states.resolver'
import { AuthLayoutComponent } from './layout/auth-layout/auth-layout/auth-layout.component'
import { MainLayoutComponent } from './layout/main-layout/main-layout/main-layout.component'
import { PublicReportsLayoutComponent } from './layout/main-layout/public-reports-layout/public-reports-layout.component'
import { UserInfoGuard } from './core/guards/user-info.guard'

export const appRoutes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: ApplicationRoutes.dashboard },

  {
    path: '',
    canActivate: [CombinedGuard],
    data: {
      guards: [CurrentUserGuard, LoggedInGuard]
    },
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        canActivate: [CombinedGuard],
        data: {
          guards: [OnlyUserGuard, CheckUserShopGuard, UserInfoGuard],
          shouldHasShop: true
        },
        children: [
          {
            path: ApplicationRoutes.dashboard,
            loadChildren: () =>
              import('./features/user-panel/dashboard/dashboard.module').then((m) => m.DashboardModule)
          },
          {
            path: ApplicationRoutes.marketPricing,
            loadChildren: () =>
              import('./features/user-panel/market-pricing/market-pricing.module').then(
                (m) => m.MarketPricingModule
              )
          },
          {
            path: ApplicationRoutes.weeklyReports,
            loadChildren: () =>
              import('./features/user-panel/weekly-reports/weekly-reports.module').then(
                (m) => m.WeeklyReportsModule
              )
          },
          {
            path: ApplicationRoutes.compSales,
            loadChildren: () =>
              import('./features/user-panel/comp-sales/comp-sales.module').then((m) => m.CompSalesModule)
          },
          {
            path: ApplicationRoutes.categories,
            loadChildren: () =>
              import('./features/user-panel/categories/categories.module').then(
                (m) => m.CategoriesModule
              )
          },
          {
            path: ApplicationRoutes.shops,
            loadChildren: () =>
              import('./features/user-panel/shops/shops.module').then((m) => m.ShopsModule)
          },
          {
            path: ApplicationRoutes.users,
            loadChildren: () =>
              import('./features/user-panel/users/users.module').then((m) => m.UsersModule)
          },
          {
            path: '',
            loadChildren: () =>
              import('./features/user-panel/trends/trends.module').then((m) => m.TrendsModule)
          },
          {
            path: '',
            loadChildren: () =>
              import('./features/user-panel/rankings/rankings.module').then((m) => m.RankingsModule)
          },
          {
            path: ApplicationRoutes.notifications,
            loadChildren: () =>
              import('./features/user-panel/notifications/notifications.module').then(
                (m) => m.NotificationsModule
              )
          },
          {
            path: ApplicationRoutes.performanceCalendar,
            loadChildren: () =>
              import('./features/user-panel/performance-calendar/performance-calendar.module').then(
                (m) => m.PerformanceCalendarModule
              )
          },
          {
            path: ApplicationRoutes.goals,
            loadChildren: () =>
              import('./features/user-panel/goals/goals.module').then((m) => m.GoalsModule)
          },
          {
            path: ApplicationRoutes.benchmark,
            loadChildren: () =>
              import('./features/user-panel/benchmark/benchmark.module').then((m) => m.BenchmarkModule)
          },
          {
            path: ApplicationRoutes.profile,
            loadChildren: () => import('./features/profile/profile.module').then((m) => m.ProfileModule)
          },
          {
            path: ApplicationRoutes.reports,
            loadChildren: () =>
              import('./features/user-panel/reports/reports.module').then((m) => m.ReportsModule)
          },
          {
            path: ApplicationRoutes.samples,
            loadChildren: () =>
              import('./features/user-panel/samples/samples.module').then((m) => m.SamplesModule)
          }
        ]
      },
      {
        path: ApplicationRoutes.enterprisePanel,
        canActivate: [OnlyEnterpriseUserGuard],
        resolve: { states: StatesResolver },
        loadChildren: () =>
          import('./features/enterprise-panel/enterprise-panel.module').then((m) => m.EnterpriseModule)
      }
    ]
  },
  {
    canActivate: [CurrentUserGuard],
    path: '',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('./features/user-account/user-account.module').then((m) => m.UserAccountModule)
  },
  {
    path: ApplicationRoutes.publicWeeklySummary,
    component: PublicReportsLayoutComponent,
    loadChildren: () =>
      import('./features/user-panel/weekly-reports-public/weekly-reports-public.module').then(
        (m) => m.WeeklyReportsPublicModule
      )
  },

  // Autovitals is a component-less route and all its functionallity handle on AutovitalsGuard
  {
    path: ApplicationRoutes.autovitals,
    canActivate: [AutovitalsGuard],
    children: [{ path: '', pathMatch: 'full', redirectTo: ApplicationRoutes.dashboard }]
  },

  // TODO: add 404 page
  { path: '**', pathMatch: 'full', redirectTo: ApplicationRoutes.dashboard }
]

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
