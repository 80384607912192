import { ICompareWithSelectionAndTime } from '@mg-platform/core/core-data-access'

export class GetCategories {
  static readonly type = '[Categories] Get Categories'
  constructor(public payload: Partial<ICompareWithSelectionAndTime>) {}
}

export class GetCategoriesList {
  static readonly type = '[Categories] Get Categories List'
  constructor(public payload: Partial<ICompareWithSelectionAndTime>) {}
}
