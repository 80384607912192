import { Component, HostListener } from '@angular/core'
import { UntilDestroy } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
  selector: 'mg-autovitals-menu',
  templateUrl: './autovitals-menu.component.html',
  styleUrls: ['./autovitals-menu.component.scss'],
  standalone: false
})
export class AutovitalsMenuComponent {
  showMenu = false
  smallMenu = false

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (event.target.innerWidth >= 976) {
      this.showMenu = false
    }

    if (event.target.innerWidth < 976) {
      this.smallMenu = false
    }
  }
}
