import { Component, inject, OnInit } from '@angular/core'
import { FeaturesService, IHeaderRouteInfo } from '@mg-platform/core/core-feature'
import {
  IUser,
  OrganizationFeatures,
  ReferralType,
  Role,
  UserAccountState,
  UserPermissionType
} from '@mg-platform/users/users-data-access'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Observable, debounceTime, filter } from 'rxjs'
import { ApplicationRoutes } from '../../../app-routes-names'
import { EnterprisePaths } from '../../../features/enterprise-panel/enterprise-panel-route-names'
import { ShopsPaths } from '../../../features/user-panel/shops/shops-route-names'
import { IShop, ShopsState } from '@mg-platform/shops/shops-data-access'
import { TrendsPaths } from '../../../features/user-panel/trends/trends-route-names'
import { NavigationEnd, Router } from '@angular/router'
import { ReportsPaths } from '../../../features/user-panel/reports/reports-route-names'
import { MobileMaxWidth } from '@mg-platform/core/core-util'
import { Store } from '@ngxs/store'
import { RankingsPaths } from '../../../features/user-panel/rankings/rankings-route-names'

@UntilDestroy()
@Component({
  selector: 'mg-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
  standalone: false
})
export class MainLayoutComponent implements OnInit {
  currentUser$: Observable<IUser> = inject(Store).select(UserAccountState.currentUser)
  shop$: Observable<IShop> = inject(Store).select(ShopsState.shop)
  shop: IShop

  window = window
  mobileMaxWidth = MobileMaxWidth

  allRoles = Role
  referralTypes = ReferralType

  userMainRoutes: IHeaderRouteInfo[] = [
    {
      label: 'Dashboard',
      icon: 'dashboard',
      path: ApplicationRoutes.dashboard
    },
    {
      label: 'Weekly Summary',
      icon: 'calendar-light',
      path: ApplicationRoutes.weeklyReports
    },
    {
      label: 'Sales Tracker',
      icon: 'sales-tracker',
      path: ReportsPaths.sales
    },
    {
      label: 'Gross Profit',
      icon: 'dollar',
      path: ReportsPaths.grossprofit
    },
    {
      label: 'Goals',
      icon: 'target',
      path: ApplicationRoutes.goals
    },
    {
      label: 'Comp Sales',
      icon: 'chart-square',
      path: ApplicationRoutes.compSales
    },
    {
      label: 'Benchmark',
      icon: 'benchmark',
      path: ApplicationRoutes.benchmark
    },
    {
      label: 'Weekly Trends',
      icon: 'chart-increase',
      path: TrendsPaths.allTrends
    },
    {
      label: 'Tire Trends',
      icon: 'wheel',
      path: TrendsPaths.tireTrends
    },
    {
      label: 'Market Pricing',
      icon: 'presentation',
      path: ApplicationRoutes.marketPricing
    },
    {
      label: 'Leaderboard',
      icon: 'rankings',
      path: RankingsPaths.leaderboard,
      hidden: !(inject(Store).selectSnapshot(UserAccountState.currentUser)?.organizationShopNumber > 1)
    },
    {
      label: 'Sales by category',
      icon: 'tag',
      path: ApplicationRoutes.categories,
      hidden: !this.featuresService.checkHasPermission(OrganizationFeatures.ServiceCategory)
    }
  ]

  userProfileRoutes: IHeaderRouteInfo[] = [
    {
      label: 'My Profile',
      icon: 'user-circle',
      path: ApplicationRoutes.profile
    },
    {
      label: 'My Groups',
      icon: 'group',
      path: ShopsPaths.groups,
      hidden:
        !inject(Store).selectSnapshot(UserAccountState.currentUser)?.isOrganizationOwner ||
        !this.featuresService.checkHasPermission(OrganizationFeatures.Groups)
    },
    {
      label: 'My Shops',
      icon: 'garage',
      path: ShopsPaths.allShops,
      exact: true
    },
    {
      label: 'Users',
      icon: 'users',
      path: ApplicationRoutes.users,
      hidden: !inject(Store)
        .selectSnapshot(UserAccountState.currentUser)
        ?.permissions?.includes(UserPermissionType.ManageShop)
    },
    {
      label: 'Notifications',
      icon: 'bell',
      path: ApplicationRoutes.notifications
    }
  ]

  enterpriseMainRoutes: IHeaderRouteInfo[] = [
    {
      label: 'Dashboard',
      icon: 'dashboard-new',
      path: EnterprisePaths.dashboard
    },
    {
      label: 'Market Pricing',
      icon: 'presentation',
      path: EnterprisePaths.marketPricing
    }
  ]

  enterpriseProfileRoutes: IHeaderRouteInfo[] = [
    {
      label: 'My Profile',
      icon: 'user-circle',
      path: ApplicationRoutes.users
    }
  ]

  constructor(
    private router: Router,
    private featuresService: FeaturesService
  ) {}

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        untilDestroyed(this)
      )
      .subscribe(() => {
        document.getElementById('main-content')?.scrollTo(0, 0)
        window.scrollTo({
          left: 0,
          top: 0,
          behavior: 'instant'
        })
      })

    this.shop$.pipe(untilDestroyed(this), debounceTime(500)).subscribe((res) => {
      this.shop = res
    })

    this.currentUser$.pipe(untilDestroyed(this)).subscribe((res) => {
      if (
        res?.permissions?.includes(UserPermissionType.ManageShop) &&
        !this.userProfileRoutes.find((el) => el.path === ShopsPaths.globalTargets)
      ) {
        this.userProfileRoutes.splice(this.userProfileRoutes.length - 1, 0, {
          label: 'Goals Settings',
          icon: 'target',
          path: ShopsPaths.globalTargets,
          exact: true
        })
      }
    })
  }
}
